<template>
  <div class="container">
    <div class="container content">
      <div class="search-type">
        <v-icon class="mdi mdi-folder" size="90" color="#373737"></v-icon>
        <div class="title-page">
          <h2 class="mt-3 mb-3">
            Files
          </h2>
        </div>
      </div>

      <div class="font-weight-bold grey--text mt-8">Filter</div>
      <div class="search-options">
        <div class="box-container">
          <div class="box-half">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                outlined
                class="ml-n6"
              ></v-text-field>
            </v-card-title>
          </div>
          <div class="box-fifth">
            <v-select
              class="pt-4 pl-md-12 filter-selections"
              v-model="practice"
              :items="['', ...Object.values(practiceDict)]"
              label="Practice"
              outlined
            ></v-select>
          </div>
          <div class="box-third">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="pt-4 pl-md-12 date-picker"
                  v-model="year"
                  label="Year"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :clearable="!!year"
                  @click:clear="() => (year = '')"
                  :persistent-placeholder="true"
                >
                  <template v-slot:append>
                    <v-icon
                      :class="{ rotate: menu }"
                      :color="menu ? 'var(--aqua)' : ''"
                      @click="menu = !menu"
                    >
                      mdi-menu-down
                    </v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="year"
                :active-picker.sync="activePicker"
                :max="new Date().getFullYear().toString()"
                min="1950"
                @click:year="saveYear"
                no-title
                reactive
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="box-fifth">
            <v-select
              class="pt-4 pl-md-12 filter-selections"
              v-model="center"
              :items="centers"
              label="Center"
              outlined
            ></v-select>
          </div>
        </div>
        <div class="box-container">
          <div class="box-quarter">
            <v-autocomplete
              v-model="grant"
              :items="grants"
              :search-input.sync="searchGrants"
              label="Grant"
              outlined
              hide-details
              clearable
              class="pt-4 pr-12 ml-n2"
              menu-props="auto"
              @change="getFiles"
              item-text="name"
              item-value="id"
            >
            </v-autocomplete>
          </div>
          <div class="box-quarter">
            <v-autocomplete
              v-model="program"
              :items="programs"
              :search-input.sync="searchPrograms"
              label="Program"
              outlined
              hide-details
              clearable
              class="pt-4 pr-12 ml-n2"
              menu-props="auto"
              @change="getFiles"
              item-text="name"
              item-value="id"
            >
            </v-autocomplete>
          </div>

          <div class="box-quarter">
            <v-autocomplete
              v-model="project"
              :items="projects"
              :search-input.sync="searchProjects"
              label="Project"
              outlined
              hide-details
              clearable
              class="pt-4 pr-12 ml-n2"
              menu-props="auto"
              @change="getFiles"
              item-text="name"
              item-value="id"
            >
            </v-autocomplete>
          </div>
          <div class="box-quarter">
            <v-autocomplete
              v-model="organization"
              :items="organizations"
              :search-input.sync="searchOrganizations"
              label="Organization"
              outlined
              hide-details
              clearable
              class="pt-4 pr-12 ml-n2"
              menu-props="auto"
              @change="getFiles"
              item-text="name"
              item-value="id"
            >
            </v-autocomplete>
          </div>
        </div>
      </div>
      <div class="results-count mt-12">
        Showing <strong>{{ count }}</strong> File<span v-if="count != 1"
          >s</span
        >
      </div>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="filesWithLocation"
          :search="search"
          multi-sort
          :loading="loading"
          loading-text="Loading... Please wait"
          @pagination="getPageCount"
        >
          <template v-slot:item.name="{ item }">
            <span class="icon-container">
              <v-list-item-avatar class="mr-2">
                <v-icon
                  :style="{
                    color: getIconColor(fileTypes['type'][item.type]),
                  }"
                  dark
                >
                  {{
                    fileTypes["type"][item.type] || "mdi-file-document-multiple"
                  }}
                </v-icon>
              </v-list-item-avatar>
              <div class="table-link">
                <a href="#" @click.prevent="downloadFile(item)">{{
                  item.name
                }}</a>
              </div>
            </span>
          </template>
          <template v-slot:item.fileLocation="{ item }">
            <span class="icon-container">
              <v-list-item-avatar class="mr-1">
                <div>
                  <div v-if="item.organization" class="icon-organization"></div>
                  <div v-if="item.project" class="icon-project"></div>
                  <div v-if="item.award" class="icon-grant"></div>
                  <div v-if="item.program" class="icon-program"></div>
                </div>
              </v-list-item-avatar>
              <a :href="getFileLocationLink(item)">
                {{ item.fileLocation }}
              </a>
            </span>
          </template>
          <template v-slot:item.center="{ item }">
            <v-chip
              v-if="item.center"
              :color="
                item.center === 'GovEx'
                  ? 'var(--govex)'
                  : item.center === 'BCPI'
                  ? 'var(--bcpi)'
                  : ''
              "
              class="white--text font-weight-medium"
              :class="{
                'govex-chip': item.center === 'GovEx',
                'bcpi-chip': item.center === 'BCPI',
              }"
            >
              {{ item.center }}
            </v-chip>
            <span v-else>-</span>
          </template>
          <template v-slot:item.practice="{ item }">
            <v-chip
              v-if="item.practice"
              class="font-weight-medium tag-chip priority-area-chip"
              >{{ practiceDict[item.practice] }}
            </v-chip>
            <span v-else>-</span>
          </template>
          <template v-slot:item.created_date="{ item }">
            <span>{{ item.created_date | formatDate | formatNull }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<style>
.icon-program {
  width: 24px;
  height: 24px;
  background-color: #4a4a4a;
  mask: url("../assets/icons/programs.svg") no-repeat center;
  -webkit-mask: url("../assets/icons/programs.svg") no-repeat center;
  mask-size: cover;
  -webkit-mask-size: cover;
}
.icon-project {
  width: 24px;
  height: 24px;
  background-color: #4a4a4a;
  mask: url("../assets/icons/projects.svg") no-repeat center / contain;
  -webkit-mask: url("../assets/icons/projects.svg") no-repeat center / contain;
}

.icon-organization {
  width: 24px;
  height: 24px;
  color: #4a4a4a;
  background-color: currentColor;
  mask: url("../assets/icons/organizations.svg") no-repeat center / contain;
  -webkit-mask: url("../assets/icons/organizations.svg") no-repeat center /
    contain;
}

.icon-grant {
  width: 24px;
  height: 24px;
  color: #4a4a4a;
  background-color: currentColor;
  mask: url("../assets/icons/awards.svg") no-repeat center / contain;
  -webkit-mask: url("../assets/icons/awards.svg") no-repeat center / contain;
}

.icon-container {
  display: flex;
  align-items: center;
}

.table-link a {
  font-family: "roboto-bold";
  text-decoration: underline;
  color: #0093ad;
}

.rotate {
  transform: rotate(180deg);
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.v-data-table__mobile-row:nth-child(2) {
  font-weight: bold;
}

.v-data-table__mobile-row:nth-child(6) {
  display: none;
}

@media screen and (max-width: 1000px) {
  .filter-selections,
  .date-picker {
    width: 85%;
  }
}
</style>

<script>
import pageCountMixin from "../mixins/pageCount";
import fileTypes from "@/fileTypes.json";

export default {
  mixins: [pageCountMixin],
  components: {},
  data() {
    return {
      files: [],
      centers: ["", "BCPI", "GovEx", "Partner"],
      search: "",
      center: "",
      practice: "",
      programs: [],
      projects: [],
      organizations: [],
      grants: [],
      program: "",
      project: "",
      organization: "",
      grant: "",
      programQueryTerm: "",
      projectQueryTerm: "",
      organizationQueryTerm: "",
      grantQueryTerm: "",
      loading: true,
      fileTypes: fileTypes,
      activePicker: null,
      menu: false,
      year: "",
      practiceDict: {
        DVP: "Academic",
        ANA: "Analytics",
        BPCDA: "BPCDA Learning and Development",
        CEGMT: "Civic Engagement",
        COM: "Communications",
        DIG: "Digital",
        FUT: "Futurism",
        INNO: "Innovation",
        LAD: "Learning and Delivery",
        RSCH: "Research",
        SDLVY: "Service Delivery",
      },
    };
  },
  created() {
    this.getFiles();
  },
  computed: {
    filesWithLocation() {
      return this.files.map((item) => {
        return {
          ...item,
          fileLocation:
            item.organization_name ||
            item.program_name ||
            item.project_name ||
            item.grant_name ||
            "",
        };
      });
    },
    headers() {
      return [
        {
          text: "File Name",
          align: "start",
          sortable: true,
          value: "name",
          cellClass: "table-item",
        },
        {
          text: "File Location",
          value: "fileLocation",
          sortable: true,
          cellClass: "table-item table-link",
        },
        {
          text: "Center",
          value: "center",
          sortable: true,
          cellClass: "table-item",
          filter: (value) => {
            if (!this.center) return true;
            return value === this.center;
          },
        },
        {
          text: "Practice",
          value: "practice",
          sortable: true,
          cellClass: "table-item",
          filter: (value) => {
            if (!this.practice) return true;
            return this.practiceDict[value] === this.practice;
          },
        },
        {
          text: "Date Added",
          value: "created_date",
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "Associated Year",
          align: " d-none",
          value: "associated_year",
          class: "d-none",
          filter: (value) => {
            if (!this.year) return true;
            return parseInt(value) === parseInt(this.year);
          },
        },
      ];
    },
    searchOrganizations: {
      get() {
        return this.organizationQueryTerm;
      },

      set(searchInput) {
        if (this.organizationQueryTerm !== searchInput) {
          this.organizationQueryTerm = searchInput;
          this.loadOrganizations();
        }
      },
    },
    searchProjects: {
      get() {
        return this.projectQueryTerm;
      },

      set(searchInput) {
        if (this.projectQueryTerm !== searchInput) {
          this.projectQueryTerm = searchInput;
          this.loadProjects();
        }
      },
    },
    searchPrograms: {
      get() {
        return this.programQueryTerm;
      },

      set(searchInput) {
        if (this.programQueryTerm !== searchInput) {
          this.programQueryTerm = searchInput;
          this.loadPrograms();
        }
      },
    },
    searchGrants: {
      get() {
        return this.grantQueryTerm;
      },

      set(searchInput) {
        if (this.grantQueryTerm !== searchInput) {
          this.grantQueryTerm = searchInput;
          this.loadGrants();
        }
      },
    },
    iconClass() {
      if (this.item.organization) return "icon-organization";
      if (this.item.project) return "icon-project";
      if (this.item.award) return "icon-grant";
      if (this.item.program) return "icon-program";
      return "";
    },
    linkUrl() {
      if (this.item.organization)
        return `/contacts/organizations/${this.item.organization.id}`;
      if (this.item.project) return `/projects/${this.item.project.id}`;
      if (this.item.award) return `/grants/${this.item.award.id}`;
      if (this.item.program) return `/programs/${this.item.program.id}`;
      return "#";
    },
  },

  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    async getFiles() {
      this.files = [];
      let url = `attachments?filter_program=${this.program ||
        ""}&filter_award=${this.grant || ""}&filter_organization=${this
        .organization || ""}&filter_project=${this.project || ""}`;
      while (url) {
        let response = await this.$api.get(url);
        await this.files.push(...response.data.results);
        url = response.data.next;
      }
      this.loading = false;
    },

    async queryEntities(entityType, searchTerm) {
      try {
        const response = await this.$api.get(
          `${entityType}?ordering=name&search=${searchTerm}`
        );
        return response.data.results;
      } catch (error) {
        console.error(`Error querying ${entityType}:`, error);
      }
    },

    async loadEntities(entityType, queryTerm, stateKey) {
      this[stateKey] = await this.queryEntities(entityType, queryTerm || "");
      this[`${stateKey}AreLoading`] = false;
    },

    async loadOrganizations() {
      await this.loadEntities(
        "contacts/organizations",
        this.organizationQueryTerm,
        "organizations"
      );
    },

    async loadPrograms() {
      await this.loadEntities("programs", this.programQueryTerm, "programs");
    },

    async loadProjects() {
      await this.loadEntities("projects", this.projectQueryTerm, "projects");
    },

    async loadGrants() {
      await this.loadEntities("awards", this.grantQueryTerm, "grants");
    },

    getFileLocationLink(item) {
      if (item.organization) {
        return `/contacts/organizations/${item.organization}`;
      } else if (item.project) {
        return `/projects/${item.project}`;
      } else if (item.award) {
        return `/grants/${item.award}`;
      } else if (item.program) {
        return `/programs/${item.program}`;
      }
      return "#";
    },

    getIconColor(icon) {
      const iconToColor = {
        "mdi-file-presentation": "var(--yellow)",
        "mdi-google-spreadsheet": "var(--green)",
        "mdi-movie": "var(--red)",
        "mdi-file-document": "var(--aqua)",
      };
      return iconToColor[icon] || "var(--aqua)";
    },

    downloadFile(attachment) {
      this.$api
        .get(`attachments/${attachment.id}/download/`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = attachment.name;
          link.click();
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    },
    saveYear(year) {
      this.year = year.toString();
      this.activePicker = "YEAR";
      this.menu = false;
    },
  },
};
</script>
